import React, { useState, useCallback } from 'react'
import { navigate } from '@reach/router'
import Select from 'react-select'
import { jsx } from '@emotion/core'

import Layout from '../layout/index'
import SEO from '../components/seo'
import myLabels from '../documents/contact'
import {
  contactPage,
  contactLargeItem,
  contactLargeItemLabel,
  contactFormRequiredLabel,
  isColorRequired,
  isColorAny,
  contactFormBody,
  contactFormItem,
  contactTextArea,
  contactFormSelect,
  contactFormInput,
  contactFlexItem,
  contactFormSubmitButton,
  isRequiredFormContent,
  isFullSizeContent
} from '../styles/contact'

type Contact = {
  type: string
  message: string
  personal: {
    name: string
    email: string
    tel: string
  }
  company: {
    name: string
    tel: string
  }
  postalCode: string
  prefectures: string
  city: string
  other: string
  validations: Array<any>
  disable: boolean
  status: string
}

export const initialState = {
  type: '',
  message: '',
  personal: {
    name: '',
    email: '',
    tel: ''
  },
  company: {
    name: '',
    tel: ''
  },
  postalCode: '',
  prefectures: '',
  city: '',
  other: '',
  validations: [
    { label: 'type', value: true },
    { label: 'message', value: true },
    { label: 'name', value: true },
    { label: 'email', value: true },
    { label: 'tel', value: true }
  ],
  disable: true,
  status: ''
}

const ContactPage: React.FC = () => {
  const [state, setState] = useState<Contact>(initialState)

  const checkValidation = useCallback(() => {
    setState((prevState: any) => {
      let validations = [prevState.type, prevState.message, prevState.personal.name, prevState.personal.email, prevState.personal.tel]
      let disable = validations.filter((e: string) => e.length === 0).length > 0 ? true : false
      return { ...prevState, disable }
    })
  }, [setState])

  const handleChange = useCallback((e: any) => {
    const { name, value } = e.target
    switch (name) {
      case 'contactMessage':
        setState((prevState: any) => {
          let validations = prevState.validations.map((item: { label: string, value: boolean }) => {
            return {
              label: item.label,
              value: item.label === 'message' ? value.length !== 0 ? true : false : item.value
            }
          })
          return { ...prevState, message: value, validations }
        })
        checkValidation()
        return
      case 'personalName':
        setState((prevState: any) => {
          let validations = prevState.validations.map((item: { label: string, value: boolean }) => {
            return {
              label: item.label,
              value: item.label === 'name' ? value.length !== 0 ? true : false : item.value
            }
          })
          let personal = {
            name: value,
            email: prevState.personal.email,
            tel: prevState.personal.tel
          }
          return { ...prevState, personal, validations }
        })
        checkValidation()
        return
      case 'personalEmail':
        setState((prevState: any) => {
          let validations = prevState.validations.map((item: { label: string, value: boolean }) => {
            return {
              label: item.label,
              value: item.label === 'email' ? value.length !== 0 ? true : false : item.value
            }
          })
          let personal = {
            name: prevState.personal.name,
            email: value,
            tel: prevState.personal.tel
          }
          return { ...prevState, personal, validations }
        })
        checkValidation()
        return
      case 'personalTel':
        setState((prevState: any) => {
          let validations = prevState.validations.map((item: { label: string, value: boolean }) => {
            return {
              label: item.label,
              value: item.label === 'tel' ? value.length !== 0 ? true : false : item.value
            }
          })
          let personal = {
            name: prevState.personal.name,
            email: prevState.personal.email,
            tel: value
          }
          return { ...prevState, personal, validations }
        })
        checkValidation()
        return
      case 'companyName':
        return setState((prevState: any) => {
          let company = {
            name: value,
            tel: prevState.company.tel
          }
          return { ...prevState, company }
        })
      case 'companyTel':
        return setState((prevState: any) => {
          let company = {
            name: prevState.company.name,
            tel: value
          }
          return { ...prevState, company }
        })
      case 'postalCode':
        return setState((prevState: any) => {
          return { ...prevState, postalCode: value }
        })
      case 'city':
        return setState((prevState: any) => {
          return { ...prevState, city: value }
        })
      case 'other':
        return setState((prevState: any) => {
          return { ...prevState, other: value }
        })
    }
  }, [setState])

  const handleContactType = async (e: any) => {
    await setState((prevState: any) => {
      let validations = prevState.validations.map((item: { label: string, value: boolean }) => {
        return {
          label: item.label,
          value: item.label === 'type' ? true : item.value
        }
      })
      return { ...prevState, type: e.label, validations }
    })
    checkValidation()
  }

  const handlePrefecture = (e: any) => {
    setState((prevState: any) => {
      return { ...prevState, prefectures: e.label }
    })
  }

  const encode = (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k])
    });
    return formData
  }

  const handleSubmit = (e: any) => {
    const data = {
      'form-name': 'contact',
      contactType: state.type,
      contactMessage: state.message,
      personalName: state.personal.name,
      personalEmail: state.personal.email,
      personalTel: state.personal.tel,
      companyName: state.company.name,
      companyTel: state.company.tel,
      postalCode: state.postalCode,
      prefectures: state.prefectures,
      city: state.city,
      other: state.other
    }

    fetch('/', {
      method: 'POST',
      body: encode(data)
    })
      .then(() => {
        setState((prevState: any) => {
          return { ...prevState, status: 'Form Submission Successful!!' }
        })
        navigate(`/thanks`)
      })
      .catch(error => {
        setState((prevState: any) => {
          return { ...prevState, status: 'Form Submission Failed!' }
        })
      })

    e.preventDefault();
  }

  return (
    <Layout>
      <SEO title='お問い合わせ' noindex={true} />
      <section css={contactPage}>
        <h2>{myLabels.title}</h2>
        <form onSubmit={handleSubmit} netlify-honeypot="bot-field" data-netlify="true" name="contact">
          <input type="hidden" name="form-name" value="contact" />
          <div css={contactLargeItem}>
            <div css={contactLargeItemLabel}>
              <label>{myLabels.titles.contact}</label>
              <span css={[contactFormRequiredLabel, isColorRequired]}>{myLabels.tags.required}</span>
            </div>
            <div css={contactFormBody}>
              <div css={contactFormItem}>
                <p>{myLabels.sub.contactType}</p>
                <div css={[contactFormSelect, !state.validations[0].value ? isRequiredFormContent : '']}>
                  <Select name="contactType" className="p-form_control_selected" onChange={handleContactType} options={myLabels.contactList} defaultValue={myLabels.placeholder.contactType}></Select>
                </div>
              </div>
              <div css={contactFormItem}>
                <p>{myLabels.sub.contactDetail}</p>
                <textarea css={[contactTextArea, !state.validations[1].value ? isRequiredFormContent : '']} name="contactMessage" placeholder={myLabels.placeholder.contactDetail} value={state.message} onChange={handleChange}></textarea>
              </div>
            </div>
          </div>

          <div css={contactLargeItem}>
            <div css={contactLargeItemLabel}>
              <label>{myLabels.titles.personal}</label>
              <span css={[contactFormRequiredLabel, isColorRequired]}>{myLabels.tags.required}</span>
            </div>
            <div css={contactFormBody}>
              <div css={contactFormItem}>
                <p>{myLabels.sub.name}</p>
                <input css={[contactFormInput, !state.validations[2].value ? isRequiredFormContent : '']} type="text" name="personalName" placeholder={myLabels.placeholder.name} value={state.personal.name} onChange={handleChange} />
              </div>
              <div css={contactFormItem}>
                <p>{myLabels.sub.email}</p>
                <input css={[contactFormInput, !state.validations[3].value ? isRequiredFormContent : '', isFullSizeContent]} type="text" name="personalEmail" placeholder={myLabels.placeholder.email} value={state.personal.email} onChange={handleChange} />
              </div>
              <div css={contactFormItem}>
                <p>{myLabels.sub.tel}</p>
                <input css={[contactFormInput, !state.validations[4].value ? isRequiredFormContent : '']} type="text" name="personalTel" placeholder={myLabels.placeholder.tel} value={state.personal.tel} onChange={handleChange} />
              </div>
            </div>
          </div>

          <div css={contactLargeItem}>
            <div css={contactLargeItemLabel}>
              <label>{myLabels.titles.company}</label>
              <span css={[contactFormRequiredLabel, isColorAny]}>{myLabels.tags.any}</span>
            </div>
            <div css={contactFormBody}>
              <div css={contactFormItem}>
                <p>{myLabels.sub.companyName}</p>
                <input css={[contactFormInput, isFullSizeContent]} type="text" name="companyName" placeholder={myLabels.placeholder.companyName} value={state.company.name} onChange={handleChange} />
              </div>
              <div css={contactFormItem}>
                <p>{myLabels.sub.tel}</p>
                <input css={contactFormInput} type="text" name="companyTel" placeholder={myLabels.placeholder.tel} value={state.company.tel} onChange={handleChange} />
              </div>
            </div>
          </div>

          <div css={contactLargeItem}>
            <div css={contactLargeItemLabel}>
              <label>{myLabels.titles.address}</label>
              <span css={[contactFormRequiredLabel, isColorAny]}>{myLabels.tags.any}</span>
            </div>
            <div css={contactFormBody}>
              <div css={contactFlexItem}>
                <div css={contactFormItem}>
                  <p>{myLabels.sub.postalCode}</p>
                  <input css={contactFormInput} type="text" name="postalCode" placeholder={myLabels.placeholder.postalCode} value={state.postalCode} onChange={handleChange} />
                </div>
                <div css={contactFormItem}>
                  <p>{myLabels.sub.prefectures}</p>
                  <div css={contactFormSelect}>
                    <Select name="prefectures" className="p-form_control_selected" defaultValue={myLabels.placeholder.prefecture} onChange={handlePrefecture} options={myLabels.prefectures}></Select>
                  </div>
                </div>
              </div>
              <div css={contactFormItem}>
                <p>{myLabels.sub.city}</p>
                <input css={[contactFormInput, isFullSizeContent]} type="text" name="city" value={state.city} onChange={handleChange} />
              </div>
              <div css={contactFormItem}>
                <p>{myLabels.sub.other}</p>
                <input css={[contactFormInput, isFullSizeContent]} type="text" name="other" value={state.other} onChange={handleChange} />
              </div>
            </div>
          </div>
          <button css={contactFormSubmitButton} type="submit" disabled={state.disable}>{myLabels.submit}</button>
        </form>
      </section>
    </Layout>
  )
}

export default ContactPage
